// Here you can add other styles
.pointer {
    cursor: pointer;
}

.centered-cell{
    vertical-align: middle;
}

.top-cell{
    vertical-align: top;
}